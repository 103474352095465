import React, { Component } from "react";
import { ContactSVG } from "../static/svgs";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/HeroSection/HeroSection";
import "./contact.scss";
import SEO from "../components/SEO";
import ContactForm from "../components/ContactsPage/ContactForm";

class Contact extends Component {
  renderHeroSection = () => (
    <HeroSection title={"Contact Me"} svg={<ContactSVG />} />
  );

  render() {
    return (
      <Layout
        location={this.props.location}
        renderOutsideContainer={this.renderHeroSection}
      >
        <SEO
          title={"Contact Me"}
          description={
            "Contact Raj Rajhans. Raj Rajhans is a web developer studying computer engineering. This blog reflects Raj's ideas, projects and learnings."
          }
          url={"/contact"}
        />

        <div className="content-container">
          <div className="contactCntnr">
            <div className="contactFormCntnr">
              <ContactForm />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Contact;
